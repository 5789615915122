<template>
  <DocumentDuplicateIcon @click="copy" class="w-5 h-5 text-white absolute top-2 right-1" v-if="!isCopied" />
  <CheckIcon class="w-5 h-5 text-white absolute top-2 right-1" v-if="isCopied" />
</template>

<script setup>
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/vue/outline'
import { ref } from 'vue'

const props = defineProps({
value: String,
})

const isCopied = ref(false)

const copy = () => {
  navigator.clipboard.writeText(props.value)
  isCopied.value = true
  setTimeout(() => isCopied.value = false, 2000)
}

</script>
