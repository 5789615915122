<script setup="">
import { ref, onMounted, computed } from 'vue'
import CopyButton from './CopyButton.vue'

const isOpen = ref(false)
const listButton = ref(null)

const props = defineProps({
  apiKeys: {
    type: [String, Array],
    required: false,
    default: () => ''
  }
})

const hasList = computed(() => {
  return Array.isArray(props.apiKeys) && props.apiKeys.length > 1 
})

const btnText = computed(() => {
  return Array.isArray(props.apiKeys) ? props.apiKeys[0] : props.apiKeys
})

</script>

<template>
  <div ref="listButton" class="listButton">
    <span
      @click.self="isOpen = !isOpen"
      class="listButton__btn"> {{ btnText }} 
      <span v-if="hasList" :class="{'active': isOpen}" class="listButton__arrow"></span> 
      <span v-else class="listButton__copy"><CopyButton :value="btnText"/></span>
    </span>
    <ul
      v-if="hasList"
      :class="{'active': isOpen}" 
      class="listButton__list">
      <li v-for="api in apiKeys" :key="api" class="listButton__item">
        {{ api }}
        <span class="listButton__copy"><CopyButton :value="api"/></span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.listButton {
  position: relative;
  padding: 3px 5px;
}

.listButton__btn{
  display: flex;
  align-items: center;
}

.listButton__list {
  position: absolute;
  background: #000;
  top: calc(100% + 5px);
  left: 0;
  max-height: 300px;
  padding: 5px;
  min-width: 200px;
  overflow-y: scroll;
  border-radius: 8px;
  transform: scaleY(0);
  transition: transform .5s;

  &.active{
    transform: scaleY(1);
  }
}

.listButton__copy{
  margin-left: 25px;
}

.listButton__item {
  text-align: left;
  padding: 3px 0;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  position: relative;
  display: flex;
  justify-content: space-between;
}

.listButton__arrow {
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-45deg) translateY(0px);
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  transition: transform .5s;

  &.active{
    transform: rotate(135deg) translateY(-5px);
  }
}

@media(max-width: 768px) {
  .listButton__list{
    top: unset;
    bottom: 0;
  }
}
</style>